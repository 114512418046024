import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { FaBars, FaTimes } from "react-icons/fa"
import { Menu } from "./NavbarMenu"
import { Button } from "./Button"
import { useSpring, animated, config } from "react-spring"
import { StaticImage } from "gatsby-plugin-image"
import { MPNormalBold } from "../styles/PageStyles"

const Header = () => {
  const useIsSsr = () => {
    const [isSsr, setIsSsr] = useState(true)
    useEffect(() => {
      setIsSsr(false)
    }, [])
    return isSsr
  }

  // HOOK FOR NAVBAR MOBILE VIEW
  const [bar, showBar] = useState(false)

  // HOOK UPON NAVBAR SCROLL
  const [navbar, setNavbar] = useState(false)

  // ANIMATION FOR THE NAVBAR
  const linkAnimation = useSpring({
    from: { transform: "translate3d(0, 30px, 0)", opacity: 0 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 800,
    config: config.wobbly,
  })

  // IF NAVBAR REACHES A CERTAIN POINT IT WILL SET TO ACTIVE
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  // CATCH SSR ERROR
  const isSsr = useIsSsr()
  if (isSsr) {
    return null
  } else {
    window.addEventListener("scroll", changeBackground)
  }

  return (
    <Nav className={navbar ? "navbar active" : "navbar"} bar={bar}>
      <NavLink to="/">
        <StaticImage
          className="logo"
          bar={bar}
          src="./multimedia/bf_logo.png"
          alt="Logo"
          placeholder="none"
        />
      </NavLink>
      {bar ? (
        <BarsClose onClick={() => showBar(false)} />
      ) : (
        <Bars onClick={() => showBar(true)} />
      )}
      <NavMenu bar={bar}>
        {Menu.map((menu, index) => (
          <NavLink
            to={menu.link}
            key={index}
            activeClassName="active-link"
            style={linkAnimation}
          >
            <span>{menu.title}</span>
          </NavLink>
        ))}
        <animated.div style={linkAnimation}>
          <Button className="inquiry" primary round href="https://www.blackfort.ph/project-estimation">
            <MPNormalBold className="btn-text">
              Get a FREE Estimate
            </MPNormalBold>
          </Button>
        </animated.div>
      </NavMenu>
    </Nav>
  )
}

export default Header

// styled-components

const Nav = styled(animated.nav)`
  background: transparent;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1300px) / 2);
  position: fixed;
  // top: 0;
  width: 100%;
  z-index: 999;
  transition: 0.5s ease-in-out;

  @media screen and (max-width: 768px) {
    padding: 0;
  }

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    justify-content: center;
  }

  &.active {
    transition: 0.5s ease-in-out;
    background: black;
  }
`

const NavLink = styled(animated(Link))`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  height: 100%;
  cursor: pointer;
  

  & .logo {
    width: 215px;
    display: ${({ bar }) => (bar ? "none" : undefined)};

    @media screen and (max-width: 280px) {
      width: 175px;
    }
  }

  &.active-link {
    span {
      color: #ffb700;
      &:after {
        display: block;
        width: 100%;
        height: 3px;
        background: #ffb700;
      }
    }
  }

  span {
    &:after {
      content: "";
      display: block;
      width: -0;
      height: 3px;
      background: #ffb700;
      transition: 0.3s ease-in-out;
    }

    &:hover {
      display: block;
      color: #ffb700;
      transform: translateY(-2px);
      transition: 0.3s ease-in-out;
      &:after {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    height: 0;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  @media screen and (max-width: 1024px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`

const NavMenu = styled(animated.div)`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: flex;
    top: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 100%;
    background: #222;
    position: absolute;
    transition: 0.5s ease-in-out;
    opacity: ${({ bar }) => (bar ? "1" : "0")};
    transform: ${({ bar }) => (bar ? "translateY(0)" : "translateY(-100%)")};
  }
`

const Bars = styled(FaBars)`
  display: none;
  @media screen and (max-width: 768px) {
    z-index: 5;
    display: block;
    position: absolute;
    top: 1;
    right: 0;
    transform: translate(-100%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

const BarsClose = styled(FaTimes)`
  display: none;
  @media screen and (max-width: 768px) {
    z-index: 5;
    display: block;
    position: absolute;
    top: 1;
    right: 0;
    transform: translate(-100%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`
