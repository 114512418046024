import React from "react"
import styled from "styled-components"
import { Col, Container, Image, Row } from "react-bootstrap"
import { Link } from "gatsby"
import fbIcon from "./multimedia/facebook-icon.png"
import instagramIcon from "./multimedia/instagram-icon.png"
import gmailIcon from "./multimedia/gmail-icon.png"
import linkedinIcon from "./multimedia/linkedin-icon.png"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        <StyledCol lg={4}>
          <Link className="footer-logo-link-wrapper" to="/">
            <StaticImage
              className="footer-logo"
              src="./multimedia/blackfort-logo-footer.png"
              alt="Footer Logo"
            />
          </Link>
        </StyledCol>
        <StyledCol paddingRight lg={8}>
          <StyledRow>
            <StyledCol lg={4} xs={12} md={12}>
              <FooterMenu isCentered>
                Company
                <StyledLink to="/about-us">
                  <span>About Us</span>
                </StyledLink>
                <StyledLink to="/services">
                  <span>Services</span>
                </StyledLink>
                <StyledLink to="/projects">
                  <span>Our Projects</span>
                </StyledLink>
              </FooterMenu>
            </StyledCol>
            <StyledCol lg={4} xs={12} md={12}>
              <FooterMenu isCentered>
                Resources
                <StyledLink to="/project-estimation">
                  <span>Project Estimation</span>
                </StyledLink>
                <StyledLink to="/careers">
                  <span>Careers</span>
                </StyledLink>
              </FooterMenu>
            </StyledCol>
            <StyledCol lg={4} xs={12} md={12}>
              <FooterMenu isCentered>
                Get in Touch
                <p>
                  <a
                    style={{ color: "#ffb700", fontWeight: "400" }}
                    href="mailto:inquiry@blackfortconsulting.com"
                  >
                    inquiry@blackfortconsulting.com
                  </a>
                </p>
                <p style={{ marginTop: "1rem", fontSize: "14px" }}>
                  Unit 6i Amber Place Building
                </p>
                <p style={{ fontSize: "14px" }}>
                  #67 Bayani Road, Western Bicutan
                </p>
                <p style={{ fontSize: "14px" }}>Taguig City</p>
              </FooterMenu>
            </StyledCol>
          </StyledRow>
        </StyledCol>
      </Row>
      <Bottom>
        <a
          href="https://www.facebook.com/Blackfort.PH"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon href src={fbIcon} alt="facebook" />
        </a>
        <a
          href="https://www.facebook.com/Blackfort.PH"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={instagramIcon} alt="twitter" />
        </a>
        <a
          href="https://www.facebook.com/Blackfort.PH"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={gmailIcon} alt="gmail" />
        </a>
        <a
          href="https://www.facebook.com/Blackfort.PH"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={linkedinIcon} alt="linkedIn" />
        </a>
      </Bottom>
    </Container>
  )
}

export default Footer

const StyledRow = styled(Row)`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: ${({ paddingRight }) => (paddingRight ? "300px" : 0)};

  @media screen and (max-width: 768px) {
    padding: 0;
  }

  .footer-logo-link-wrapper {
    display: flex;
    justify-content: center;
  }

  & .footer-logo {
    width: 40%;
  }
`

const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2em;
  // padding: 0 5em 0 0;
  white-space: nowrap;
  width: 100%;
  color: #ffb700;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    margin-top: 15px;
    justify-content: ${({ isCentered }) => (isCentered ? "center" : undefined)};
    align-items: ${({ isCentered }) => (isCentered ? "center" : undefined)};
  }
`

const StyledLink = styled(Link)`
  color: white;
  font-weight: 400;
  display: flex;

  span {
    &:after {
      content: "";
      display: block;
      width: -0;
      height: 3px;
      background: #ffb700;
      transition: 0.3s ease-in-out;
    }

    &:hover {
      display: block;
      color: #ffb700;
      transform: translateY(-2px);
      transition: 0.3s ease-in-out;
      &:after {
        width: 100%;
      }
    }
  }
`

const Bottom = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5%;
  background: #ffb700;
  width: 100%;
  height: 5vh;
`

const Icon = styled(Image)`
  margin-right: 5px;
  width: 1.5em;
  height: 1.5em;
`
