import { createGlobalStyle } from "styled-components"
import "@fontsource/maven-pro"
import "@fontsource/maven-pro/500.css"
import "@fontsource/maven-pro/700.css"
import "@fontsource/roboto"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/900.css"

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        text-decoration: none !important;
        font-family: "Roboto";
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.08em;
        color: #FFFFFF;
        box-sizing: border-box;
    }

    html, body {
        min-height: 100%;
        overflow-x: hidden; // Navbar bars
    }
    
    body {
        width: 100%;
        overflow-y: hidden;
        background-color: black;
    }

    body p, h1 {
        margin-bottom: 0;
    }

    footer p {
    text-align: center;
    margin: 40px auto;

    .layout {
        max-width: 1300px;
        margin: 0 auto;
    }
}
`
