import styled from "styled-components"

// Roboto Large
export const RobotoLarge = styled.h1`
  font-weight: 900;
  font-size: clamp(48px, 5vw, 64px);
  line-height: 60px;
`
// Roboto Large-2
export const RobotoLarge2 = styled.p`
  font-weight: 500;
  // font-size: 64px;
  font-size: clamp(32px, 5vw, 64px);
  line-height: 60px;
`
// Roboto Medium
export const RobotoMedium = styled.h1`
  font-weight: 900;
  font-size: 36px;
  line-height: clamp(36px, 5vw, 60px);
  padding-top: ${({ lineBreak }) => (lineBreak ? "15px" : 0)};
`
// Roboto Small
export const RobotoSmall = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-top: ${({ lineBreak }) => (lineBreak ? "15px" : 0)};

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
`
// Roboto Normal
export const RobotoNormal = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
`
// Roboto Normal-2
export const RobotoNormal2 = styled.p`
  font-weight: normal;
  font-size: clamp(16px, 5vw, 18px);
  line-height: 26px;
  padding-top: ${({ lineBreak }) => (lineBreak ? "15px" : 0)};

  @media screen and (max-width: 768px) {
    text-align: ${({ alignCenter }) => (alignCenter ? "center" : undefined)};
  }
`
// Roboto Forms
export const RobotoForms = styled.p`
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
`
// Maven Pro Large
export const MPLarge = styled.h1`
  font-family: "Maven Pro";
  font-weight: 700;
  // font-size: 48px;
  font-size: clamp(32px, 5vw, 48px);
  line-height: clamp(40px, 5vw, 56px);
`
// Maven Pro Medium
export const MPMedium = styled.h1`
  font-family: "Maven Pro";
  font-weight: ${({ normal }) => (normal ? "400" : "700")};
  font-size: clamp(26px, 5vw, 42px);
  line-height: clamp(34px, 5vw, 50px);
`
// Maven Pro Normal
export const MPNormal = styled.p`
  font-family: "Maven Pro";
  font-weight: normal;
  // font-size: 18px;
  font-size: clamp(16px, 5vw, 18px);
  line-height: 20px;
`

// Maven Pro Normal Bold
export const MPNormalBold = styled.p`
  font-family: "Maven Pro";
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`

// Maven Pro Accent
export const MPAccent = styled.p`
  font-family: "Maven Pro";
  font-weight: bold;
  font-size: clamp(16px, 5vw, 24px);
  line-height: clamp(24px, 5vw, 28px);
  padding-top: ${({ lineBreak }) => (lineBreak ? "15px" : 0)};
`
