import React, { useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { GlobalStyle } from "../styles/GlobalStyles"
import Navbar from "./Navbar"
import Footer from "./Footer"
import AOS from "aos"
import "aos/dist/aos.css"

export default function Layout({ children }) {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true })
  }, [])
  return (
    <div className="layout">
      <GlobalStyle />
      <Navbar />
      <div className="content">{children}</div>
      <Footer />
    </div>
  )
}
