import styled from "styled-components"
import { Link } from "gatsby"

export const Button = styled(Link)`
  background: #ffb700;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "16px 40px" : "10px 24px")};
  color: #000000;
  font-family: "Maven Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  outline: none;
  border: none;
  min-width: 100px;
  cursor: ${({ primary }) => (primary ? "pointer" : undefined)};
  text-decoration: none;
  transition: ${({ primary }) =>
    primary ? "0.3s ease-in-out !important" : undefined};
  border-bottom-right-radius: ${({ round }) => (round ? "20px" : undefined)};

  & .btn-text {
    transition: 0.3s ease-in-out;
    color: #000000;
  }

  &:hover {
    background: ${({ primary }) => (primary ? "#FF4700" : "#FFB700")};
    transform: ${({ primary }) => (primary ? "translateY(-2px)" : undefined)};
    color: ${({ primary }) => (primary ? "#ffffff" : "#000000")};

    & .btn-text {
      color: #ffffff;
    }
  }

  @media screen and (max-width: 768px) {
    background: ${({ primary }) => (primary ? "#FF4700" : "#FFB700")};
    color: white;
  }
`
